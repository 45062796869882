import React from 'react'

const App = () => {

    const d = new Date()
    const year = d.getFullYear()

  return (
    <>

        <div className="bg-white dark:bg-gray-900">
            <div className="flex justify-center h-screen">
                <div className="hidden bg-cover lg:block lg:w-2/3" style={{backgroundImage: "url(/static/fast-image.jpg"}}>
                    <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
                        <div>
                            <h2 className="text-2xl font-bold text-white sm:text-3xl">Team Fstrr 🚀</h2>

                            <p className="max-w-xl mt-3 text-gray-300">
                                Greetings, Team FSTRR! This secure portal provides access to your dedicated FSTRR dashboard, streamlining your work and keeping you connected. Please enter your login credentials below to begin.
                                <br />
                                <br />
                                <b>Team FSTRR Account Credentials:</b>
                                <br />
                                <br />
                                These were conveniently included in your initial onboarding email for easy access.
                                <br />
                                <br />
                                <b>Not a Team FSTRR Member?</b>
                                <br />
                                <br />
                                We're always looking for talented individuals to join our fast-paced team! Visit <a className='hover:underline hover:text-red-400 duration-300' href="https://career.fstrr.com/">Careers at FSTRR (career.fstrr.com)</a> to explore open positions, application details, and learn more about exciting opportunities.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
                    <div className="flex-1">
                        <div className="text-center">
                            <div className="flex justify-center mx-auto">
                                <img className="w-auto h-7 sm:h-8" src="/static/logo.png" alt="" />
                            </div>

                            <p className="mt-3 text-gray-500 dark:text-gray-300">Sign in to access your account</p>
                        </div>

                        <div className="mt-8">
                            <div>
                                <div>
                                    <label htmlFor="email" className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email Address</label>
                                    <input type="email" name="email" id="email" placeholder="team@fstrr.com" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-red-400 dark:focus:border-red-400 focus:ring-red-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                </div>

                                <div className="mt-6">
                                    <div className="flex justify-between mb-2">
                                        <label htmlFor="password" className="text-sm text-gray-600 dark:text-gray-200">Password</label>
                                    </div>

                                    <input type="password" name="password" id="password" placeholder="Your Password" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-red-400 dark:focus:border-red-400 focus:ring-red-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                </div>

                                <div className="mt-6">
                                    <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-300 transform bg-red-500 rounded-lg hover:bg-red-400 focus:outline-none focus:bg-red-400 focus:ring focus:ring-red-300 focus:ring-opacity-50">
                                        Sign in
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footer className="bg-white border-t">
            <div className="container px-6 py-8 mx-auto">
                <div className="flex flex-col items-center text-center">
                    <a href="https://www.fstrr.com/">
                        <img className="w-auto h-7" src="/static/logo.png" alt="Fstrr" />
                    </a>

                    <div className="flex flex-wrap justify-center mt-6 -mx-4">
                        <a href="https://www.fstrr.com/legal/privacy-policy/" className="mx-4 text-sm text-gray-600 transition-colors duration-300 hover:text-red-500 dark:text-gray-300 dark:hover:text-red-400"> Privacy Policy </a>
                        
                        <a href="https://www.fstrr.com/legal/terms-of-service/" className="mx-4 text-sm text-gray-600 transition-colors duration-300 hover:text-red-500 dark:text-gray-300 dark:hover:text-red-400"> Terms of Service </a>
                        
                        <a href="https://career.fstrr.com/" className="mx-4 text-sm text-gray-600 transition-colors duration-300 hover:text-red-500 dark:text-gray-300 dark:hover:text-red-400"> Career at Fstrr </a>

                        <a href="https://www.fstrr.com/contact/" className="mx-4 text-sm text-gray-600 transition-colors duration-300 hover:text-red-500 dark:text-gray-300 dark:hover:text-red-400"> Contact </a>

                        <a href="https://www.fstrr.com/legal/report/" className="mx-4 text-sm text-gray-600 transition-colors duration-300 hover:text-red-500 dark:text-gray-300 dark:hover:text-red-400"> Report </a>
                    </div>

                </div>

                <hr className="my-6 border-gray-200 md:my-10 dark:border-gray-700" />

                <div className="flex flex-col items-center sm:flex-row sm:justify-between">
                    <p className="text-sm text-gray-500 dark:text-gray-300">© Copyright {year}. All Rights Reserved.</p>

                    <div className="flex -mx-2">

                        <a href="https://www.stalicon.com/" className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-lime-500 dark:hover:text-red-400">
                            Secured by
                            <img className='w-24' src="https://c.a-1.me/__root__/static/stalicon-logo.png" alt="Stalicon" />
                        </a>

                    </div>
                </div>
            </div>
        </footer>
        
    </>
  )
}

export default App